import React, { useState } from "react";
import List from "@mui/material/List";
import Link from 'next/link';
import Collapse from "@mui/material/Collapse";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export default function UsersMenu() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary='Kullanıcılar' />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <Link href='/users/create-user' style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary='Yeni Kullanıcı' />
            </ListItemButton>
          </ Link>
          <Link href='/users/user-list' style={{color: 'unset', textDecoration: 'unset'}}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <PersonSearchIcon />
            </ListItemIcon>
            <ListItemText primary='Kullanıcı Listesi' />
          </ListItemButton>
          </ Link>
          <Link href='/users/user-actions' style={{color: 'unset', textDecoration: 'unset'}}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary='Kullanıcı İşlemleri' />
          </ListItemButton>
          </ Link>
        </List>
      </Collapse>
    </div>
  );
}
