import React, { ReactNode, useState } from "react";
import axios from "axios";
import { useRouter } from "next/router";

const AuthContext = React.createContext({
  token: "",
  login: (formData) => {},
  logout: () => {},
  isAuthenticated: false,
  errorMessage: "",
});

export const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const router = useRouter();
  
  const login = (formData) => {
    if (formData == null || formData.email == "" || formData.password == "")
      return;
    const options = {
      method: "POST",
      url: process.env.RESTURL + "auth/login",
      headers: { "Content-Type": "application/json" },
      data: {
        Email: formData.email,
        Password: formData.password,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        setIsAuthenticated(true);
        setToken(response.data.accessToken);
      })
      .catch(function (error) {
        if (error.response) {
          setErrorMessage(
            error.response.data + error.response.status + error.response.headers
          );
        } else if (error.request) {
          setErrorMessage(error.response);
        } else {
          setErrorMessage(error.message);
        }
        setErrorMessage(error.config);
      });
  };

  const logout = () => {
    setIsAuthenticated(false);
    setToken("");
    router.push("/")
  };
  const context = { token, isAuthenticated, login, logout, errorMessage };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
