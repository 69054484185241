import React, { useState } from "react";
import List from "@mui/material/List";
import Link from 'next/link';
import Collapse from "@mui/material/Collapse";
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import GarageIcon from '@mui/icons-material/Garage';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RuleIcon from '@mui/icons-material/Rule';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export default function VehicleMenu() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <DriveEtaIcon />
        </ListItemIcon>
        <ListItemText primary='Araçlar' />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
        <Link href='/vehicle/makes-list' style={{color: 'unset', textDecoration: 'unset'}}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <GarageIcon />
            </ListItemIcon>
            <ListItemText primary='Üretici Listesi' />
          </ListItemButton>
          </ Link>
          <Link href='/vehicle/missing-tsb-list' style={{color: 'unset', textDecoration: 'unset'}}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <RuleIcon />
            </ListItemIcon>
            <ListItemText primary='TSB Kodu Eksik Araçlar' />
          </ListItemButton>
          </ Link>
        </List>
      </Collapse>
    </div>
  );
}
