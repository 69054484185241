import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import UsersMenu from './users';
import IndicataMenu from './indicata';
import VehicleMenu from './vehicle';
import ReportsMenu from './reports';
import SystemMenu from './system';
import Link from 'next/link';

export const mainListItems = (
  <div>
    <Link href ='/' style={{color: 'unset', textDecoration: 'unset'}}>
    <ListItemButton>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    </Link>
    
    <UsersMenu />
    <IndicataMenu />
    <VehicleMenu />
    <ReportsMenu />
    <SystemMenu />
  </div>
);
