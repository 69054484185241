import React, { useState } from "react";
import List from "@mui/material/List";
import Link from 'next/link';
import Collapse from "@mui/material/Collapse";
import MemoryIcon from '@mui/icons-material/Memory';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export default function SystemMenu() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <MemoryIcon />
        </ListItemIcon>
        <ListItemText primary='Sistem' />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <Link href='/system/system-report' style={{color: 'unset', textDecoration: 'unset'}}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <WysiwygIcon />
              </ListItemIcon>
              <ListItemText primary='Sistem Raporu' />
            </ListItemButton>
          </ Link>
        </List>
      </Collapse>
    </div>
  );
}
